import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import {
  GroupArrayOfObjects,
  createPathWithCategories,
  destructureUrls,
} from "../utils"

const DetailPageTemplate = ({ pageContext }) => {
  // console.log(data)
  //   const allposts = data.allWpVrewardsManagerPost
  const baseurl = "https://docs.vantagecircle.com"

  const { article_tag, url, data, allPosts } = pageContext

  let groupedData = GroupArrayOfObjects(allPosts, "id")

  console.log()

  let breadcrumbTextsArr = url.split("/")

  return (
    <Layout includeSearchBar={true}>
      <section className="container px-6 max-w-6xl mx-auto py-10 md:pb-20">
        <div className="site-breadcrumb">
          <Link className="text-purple-500 hover:underline" to="/">
            Home
          </Link>
          &nbsp;>&nbsp;
          <Link
            className="text-purple-500 hover:underline capitalize"
            to={`/${breadcrumbTextsArr[1]}`}
          >
            {destructureUrls(breadcrumbTextsArr[1])}
          </Link>
          &nbsp;>&nbsp;
          <Link
            className="text-purple-500 hover:underline capitalize"
            to={`/${breadcrumbTextsArr[1]}/${breadcrumbTextsArr[2]}`}
          >
            {destructureUrls(breadcrumbTextsArr[2])}
          </Link>
          &nbsp;>&nbsp;
          <Link
            className="text-purple-500 hover:underline capitalize"
            to={`/${breadcrumbTextsArr[1]}/${breadcrumbTextsArr[2]}/${breadcrumbTextsArr[3]}`}
          >
            {destructureUrls(breadcrumbTextsArr[3])}
          </Link>
          &nbsp;>&nbsp;
          <span className="" dangerouslySetInnerHTML={{ __html: data.title }} />
        </div>
        <div className="mt-10 md:mt-0 grid grid-cols-1 sm:gap-3 md:gap-9 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 border-b border-gray-light pb-20">
          <div className="mt-3 col-span-2 vc-doc-content">
            <div className="section-title">{data.title}</div>
            <div className="flex items-center mb-10">
              <div className="mr-3">
                <img
                  className="w-16 rounded-full"
                  src={data.authorImg}
                  alt={data.authorName}
                />
              </div>
              <div>
                <div className="text-base text-gray-500">{data.authorName}</div>
                <div className="text-xs text-gray-200">
                  Updated On {data.modifiedGmt.split("T")[0]}
                </div>
              </div>
            </div>
            <div dangerouslySetInnerHTML={{ __html: data.content }} />
          </div>
          <div className="mt-8 md:pl-10 text-gray-500 art-in-sect">
            <div className="font-bold mb-3 md:ml-4">
              Articles in this section
            </div>
            {groupedData[article_tag].reverse().map(node => (
              <div key={node.data.id}>
                <Link
                  className="text-sm py-3 px-4 mb-1 block hover:bg-purple-50 hover:rounded-lg"
                  to={createPathWithCategories(
                    node.data.uri,
                    node.data.categories.nodes[0].slug,
                    node.data.slug
                  )}
                >
                  {node.data.title}
                </Link>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-10">
          <ul className="post-share flex justify-start ml-0 items-center mb-5">
            <li className="text-base mr-2 font-bold">Share</li>
            <li className="mr-3">
              <a
                rel="nofollow"
                href={
                  "https://www.facebook.com/sharer/sharer.php?u=" +
                  baseurl +
                  url
                }
                className="facebook"
                target="_blank"
                title="Facebook"
                rel="noopener noreferrer"
              >
                <svg
                  id="Bold"
                  enableBackground="new 0 0 24 24"
                  height="20"
                  viewBox="0 0 24 24"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="m15.997 3.985h2.191v-3.816c-.378-.052-1.678-.169-3.192-.169-3.159 0-5.323 1.987-5.323 5.639v3.361h-3.486v4.266h3.486v10.734h4.274v-10.733h3.345l.531-4.266h-3.877v-2.939c.001-1.233.333-2.077 2.051-2.077z"
                    fill="#3b5998"
                  ></path>
                </svg>
              </a>
            </li>
            <li className="mr-3">
              <a
                rel="nofollow"
                href={"https://linkedin.com/shareArticle?url=" + baseurl + url}
                className="linkedin"
                title="Linkedin"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  id="Bold"
                  enableBackground="new 0 0 24 24"
                  height="20"
                  viewBox="0 0 24 24"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="m23.994 24v-.001h.006v-8.802c0-4.306-.927-7.623-5.961-7.623-2.42 0-4.044 1.328-4.707 2.587h-.07v-2.185h-4.773v16.023h4.97v-7.934c0-2.089.396-4.109 2.983-4.109 2.549 0 2.587 2.384 2.587 4.243v7.801z"
                    fill="#0077B5"
                  />
                  <path d="m.396 7.977h4.976v16.023h-4.976z" fill="#0077B5" />
                  <path
                    d="m2.882 0c-1.591 0-2.882 1.291-2.882 2.882s1.291 2.909 2.882 2.909 2.882-1.318 2.882-2.909c-.001-1.591-1.292-2.882-2.882-2.882z"
                    fill="#0077B5"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a
                rel="nofollow"
                href={
                  "https://twitter.com/share?url=" +
                  baseurl +
                  url +
                  "&text=" +
                  data.title
                }
                className="twitter"
                title="Twitter"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  id="Bold"
                  enableBackground="new 0 0 24 24"
                  height="20"
                  viewBox="0 0 24 24"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="m21.534 7.113c.976-.693 1.797-1.558 2.466-2.554v-.001c-.893.391-1.843.651-2.835.777 1.02-.609 1.799-1.566 2.165-2.719-.951.567-2.001.967-3.12 1.191-.903-.962-2.19-1.557-3.594-1.557-2.724 0-4.917 2.211-4.917 4.921 0 .39.033.765.114 1.122-4.09-.2-7.71-2.16-10.142-5.147-.424.737-.674 1.58-.674 2.487 0 1.704.877 3.214 2.186 4.089-.791-.015-1.566-.245-2.223-.606v.054c0 2.391 1.705 4.377 3.942 4.835-.401.11-.837.162-1.29.162-.315 0-.633-.018-.931-.084.637 1.948 2.447 3.381 4.597 3.428-1.674 1.309-3.8 2.098-6.101 2.098-.403 0-.79-.018-1.177-.067 2.18 1.405 4.762 2.208 7.548 2.208 8.683 0 14.342-7.244 13.986-14.637z"
                    fill="#1da1f2"
                  ></path>
                </svg>
              </a>
            </li>
          </ul>
          <h2 className="section-title1">
            Most Viewed Articles for Vantage Rewards
          </h2>
          <div className="md:flex justify-start viewedart">
            <div className="md:mr-10">
              <div className="text-sm mb-1">
                <Link
                  className="hover:underline"
                  to="/vantage-rewards/employees/recognition-awards/how-do-i-nominate-my-colleague-for-a-monetary-award/"
                >
                  How do I nominate my colleague for a monetary award?
                </Link>
              </div>
              <div className="text-sm mb-1">
                <Link
                  className="hover:underline"
                  to="/vantage-rewards/employees/recognition-awards/how-do-i-appreciate-my-colleagues/"
                >
                  How do I appreciate my colleagues?
                </Link>
              </div>
            </div>
            <div>
              <div className="text-sm mb-1">
                <Link
                  className="hover:underline"
                  to="/vantage-rewards/employees/mobile/how-do-i-recognize-my-peers-from-the-vantage-circle-mobile-app/"
                >
                  How do I recognize my peers from the Vantage Circle Mobile
                  App?
                </Link>
              </div>
              <div className="text-sm mb-1">
                <Link
                  className="hover:underline"
                  to="/vantage-rewards/employees/getting-started/how-do-i-invite-my-colleagues-to-sign-up-for-vantage-circle/"
                >
                  How do I invite my colleagues to sign up for Vantage Rewards?
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default DetailPageTemplate
